<template>
  <b-tr>
    <b-td>{{ formatTime(record.appointmentStartTime) }}</b-td>
    <b-td>{{ record.lastName }}</b-td>
    <b-td>{{ record.postcode }}</b-td>
    <b-td>
      <b-select v-if="!record.outcome" v-model="outcome" :disabled="submitting">
        <b-select-option :value="null">Select</b-select-option>
        <template v-for="option of outcomesValues">
          <b-select-option v-if="option.value"  :key="option.text" :value="option.value">{{ option.text }}</b-select-option>
          <b-select-option-group v-else-if="option.options" :key="option.text" :label="option.text">
            <b-select-option  v-for="subOption of option.options" :key="subOption.text" :value="subOption.value">{{ subOption.text }}</b-select-option>
          </b-select-option-group>
        </template>
      </b-select>
      <template v-else>{{ outcomeText }}</template>
    </b-td>
    <b-td>
      <b-form-input v-if="!record.outcome && outcome && outcome.showDate" v-model="reappointmentDate" :disabled="submitting" :readonly="!outcome.editableDate" :min="firstReappointmentDate" type="date" />
      <template v-else>{{ callbackDate }}</template>
    </b-td>
    <b-td>
      <b-button v-if="outcome && !record.outcome" :disabled="submitting" variant="primary" @click="submit">Submit</b-button>
    </b-td>
  </b-tr>
</template>

<script lang="ts">
import { AppointmentOutcome, AppointmentOutcomeAnalysis, TodaysPresentationsQuery, Appointment } from '../queries/index'
import { defineComponent, ref, getCurrentInstance, computed, watchEffect } from 'vue'
import moment from 'moment-timezone'
import { TIMEZONE } from '@iris/constants'

type TDropDownValue = Required<Pick<Appointment, 'outcome' | 'outcomeAnalysis'>> & {
  showDate?: boolean
  editableDate?: boolean
}
type TOption<T> = {
  text: string
  value?: T
}
const outcomesValues: Array<TOption<TDropDownValue> & {
  options?: TOption<TDropDownValue>[]
}> = [
  { value: { outcome: AppointmentOutcome.Noshow, outcomeAnalysis: null, showDate: true }, text: 'No Show' },
  {
    text: 'Think about it',
    options: [
      { value: { outcome: AppointmentOutcome.PresnosaleThinkaboutit, outcomeAnalysis: AppointmentOutcomeAnalysis.Thinkaboutterm }, text: 'Think about term' },
      { value: { outcome: AppointmentOutcome.PresnosaleThinkaboutit, outcomeAnalysis: AppointmentOutcomeAnalysis.Thinkaboutprice }, text: 'Think about price' },
      { value: { outcome: AppointmentOutcome.PresnosaleThinkaboutit, outcomeAnalysis: AppointmentOutcomeAnalysis.Thinkaboutchildcommitment }, text: 'Think about child commitment' }
    ] },
  { value: { outcome: AppointmentOutcome.Presnosalecantafford, outcomeAnalysis: null }, text: 'can\'t afford' },
  { value: { outcome: AppointmentOutcome.Presnosalecantaffordunemployed, outcomeAnalysis: null }, text: 'can\'t afford unemployed' },
  { value: { outcome: AppointmentOutcome.Presnosalereceptiononly, outcomeAnalysis: null }, text: 'reception only' },
  { value: { outcome: AppointmentOutcome.PresnosaleY11only, outcomeAnalysis: null }, text: 'Y11 only' },
  { value: { outcome: AppointmentOutcome.Presnosalespecialneeds, outcomeAnalysis: null }, text: 'special needs' },
  { value: { outcome: AppointmentOutcome.Presnosalechildcommitment, outcomeAnalysis: null }, text: 'child commitment' },
  { value: { outcome: AppointmentOutcome.PresnosaleEnglishtoopoor, outcomeAnalysis: null }, text: 'English too poor' },
  { value: { outcome: AppointmentOutcome.PresnosalePartnertosee, outcomeAnalysis: null }, text: 'Partner to see' },
  { value: { outcome: AppointmentOutcome.PresnosalePrivatetutorwanted, outcomeAnalysis: null }, text: 'Private tutor wanted' },
  { text: 'Office Cancel',
    options: [
      { value: { outcome: AppointmentOutcome.OfficeCancelreappoint, outcomeAnalysis: null, showDate: true }, text: 'Office Cancel reappoint' },
      { value: { outcome: AppointmentOutcome.OfficeCanxreappointalreadybooked, outcomeAnalysis: null }, text: 'Office Canx – reappoint – already booked' }
    ] },
  { text: 'Customer Cancel',
    options: [
      { value: { outcome: AppointmentOutcome.CustomerCancelreappoint, outcomeAnalysis: null, showDate: true, editableDate: true }, text: 'Customer Cancel - reappoint' },
      { value: { outcome: AppointmentOutcome.CustomerCancelnoreappoint, outcomeAnalysis: null }, text: 'Customer Cancel - no reappoint' },
      { value: { outcome: AppointmentOutcome.CustomerCanxreappointalreadybooked, outcomeAnalysis: null }, text: 'Customer Canx - reappoint – already booked' }
    ] }
]

const flattenedValues = outcomesValues.map(v => v.options ? v.options : v).flat()

export default defineComponent<{record: TodaysPresentationsQuery['appointments'][0]}>({
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  emits: ['select'],
  setup (props, { emit }) {
    const outcome = ref<null | TDropDownValue>(null)
    const submitting = ref(false)
    const firstReappointmentDate = moment.tz(TIMEZONE).add({ days: 1 }).startOf('day').format('YYYY-MM-DD')

    const reappointmentDate = ref<string | null>(null)

    watchEffect(() => {
      if (outcome.value && outcome.value.showDate) {
        reappointmentDate.value = firstReappointmentDate
      } else {
        reappointmentDate.value = null
      }
    })

    const submit = () => {
      if (outcome.value) {
        submitting.value = true
        emit('select', {
          id: props.record.id,
          outcome: outcome.value.outcome,
          outcomeAnalysis: outcome.value.outcomeAnalysis,
          callbackDate: reappointmentDate.value
        })
      }
    }

    const outcomeText = computed(() => {
      if (!props.record.outcome) {
        return ''
      }
      const match = flattenedValues.find(r => {
        return r.value && r.value.outcome === props.record.outcome && r.value.outcomeAnalysis === props.record.outcomeAnalysis
      })
      return match ? match.text : ''
    })

    const callbackDate = computed(() => {
      if (!props.record.callbackDate) {
        return ''
      }
      return moment(props.record.callbackDate).format('DD/MM/YYYY')
    })
    const formatTime = (v: string) => moment.tz(v, TIMEZONE).format('hh:mm A')
    return {
      outcomesValues,
      outcome,
      submitting,
      formatTime,
      submit,
      outcomeText,
      flattenedValues,
      reappointmentDate,
      firstReappointmentDate,
      callbackDate
    }
  }
})
</script>
