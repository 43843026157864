<template>
  <div>
    <h3>Presentations that require an Outcome</h3>
    <div v-if="loading">
      LOADING...
    </div>
    <template v-else>
      <div v-if="error">
        {{ error }}
      </div>
      <b-table-simple hover striped v-else-if="result && result.appointments">
        <b-thead>
          <b-th>Time</b-th>
          <b-th>Last Name</b-th>
          <b-th>Postcode</b-th>
          <b-th>Outcome</b-th>
          <b-th></b-th>
          <b-th></b-th>
        </b-thead>
        <b-tbody>
          <template v-for="(appts, date) of presentationsGroupedByDate">
            <b-tr :key="date">
              <b-th colspan="6">{{ formatDate(date) }}</b-th>
            </b-tr>
            <presentation v-for="record of appts" :record="record" :key="record.id" @select="outcomePresentation" />
          </template>

        </b-tbody>
      </b-table-simple>
    </template>
    <b-button to="." variant="danger">Exit to Main Menu</b-button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, getCurrentInstance } from 'vue'
import { useTodaysPresentationsQuery, useOutcomeAppointmentMutation, OutcomeAppointmentMutationVariables } from './queries'
import Presentation from './components/Presentation.vue'
import { groupBy } from 'lodash'
import moment from 'moment-timezone'
import { TIMEZONE } from '@iris/constants'

export default defineComponent({
  components: {
    Presentation
  },
  setup () {

    const { $route } = getCurrentInstance()!.proxy as Vue
    const momentTz =  (date?: any) => moment.tz(date, TIMEZONE)

    const { result, loading, error } = useTodaysPresentationsQuery(computed(() => {
      return {
        start: momentTz().subtract({ days: 30 }).startOf('day').toISOString(),
        end: momentTz().endOf('day').subtract({ days: $route.query.outstanding === '1' ? 1 : 0 }).toISOString(),
        limitToOwn: $route.query.outstanding === '1'
      }
    }))

    /** presentations grouped by date */
    const presentationsGroupedByDate = computed(() => {
      return groupBy((result.value && result.value.appointments) || [], (appointment) => {
        return moment.tz(appointment.appointmentStartTime, TIMEZONE).endOf('day').toISOString()
      })
    })

    const updateAppointment = useOutcomeAppointmentMutation({})

    const formatDate = (date: string | number): string => moment.tz(date, TIMEZONE).format('LL')

    const outcomePresentation = (doc: OutcomeAppointmentMutationVariables) => {
      return updateAppointment.mutate(doc)
    }

    return {
      loading,
      error,
      result,
      presentationsGroupedByDate,
      formatDate,
      outcomePresentation
    }
  }
})
</script>
